<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #searchSlot>
        <v-select
          :options="statusObj"
          v-model="searchParam.statusList"
          @change="$refs.list.search()"
          label="提议状态"
        />
        <v-date-picker
          label="发起时间"
          v-model="proposeTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="请选择开始时间"
          endPlaceholder="请选择结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
        <v-button
          v-if="scope.row.status == 10"
          text="公示不通过"
          type="text"
          disabled
        />
        <v-button
          v-else-if="scope.row.status == 9"
          text="公示通过"
          type="text"
          disabled
        />
        <v-button v-else text="编辑" type="text" @click="examine(scope.row)" />
      </template>
    </v-list>
    <!-- 编辑 -->
    <v-dialog title="编辑" v-model="showDialog1" width="35%" @confirm="submit1">
      <el-form label-position="right" label-width="120px">
        <el-form-item label="提议发起人">
          <v-input v-model="form1.proposeUserName" disabled />
        </el-form-item>
        <el-form-item label="提议议题">
          <v-input v-model="form1.proposeTitle" disabled />
        </el-form-item>
        <el-form-item label="提议内容">
          <v-input v-model="form1.proposeContent" type="textarea" disabled />
        </el-form-item>
        <el-form-item label="上传照片">
          <v-upload :limit="9" :imgUrls="form1.proposeAnnexFiles" disabled />
        </el-form-item>
        <el-form-item label="审核通过时间">
          <v-input v-model="form1.shenheTime" disabled />
        </el-form-item>
        <el-form-item label="审议通过时间">
          <v-input v-model="form1.shenyiTime" disabled />
        </el-form-item>
        <el-form-item label="商议通过时间">
          <v-input v-model="form1.shangyiTime" disabled />
        </el-form-item>
        <el-form-item label="会议时间">
          <v-date-picker
            v-model="jueyiTime"
            type="datetimerange"
            formatValue="yyyy-MM-dd HH:mm:ss"
            startPlaceholder="请选择开始时间"
            endPlaceholder="请选择结束时间"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item label="会议地点">
          <v-input v-model="form1.voteAddr" disabled />
        </el-form-item>
        <el-form-item label="主持人">
          <v-input v-model="form1.voteHost" disabled />
        </el-form-item>
        <el-form-item label="应到人数">
          <v-input
            v-model="form1.votePeopleNumber"
            disabled
            placeholder=""
            :width="250"
            min="1"
            type="number"
          ></v-input>
        </el-form-item>
        <el-form-item label="表决主体">
          <v-select
            clearable
            :multiple="true"
            v-model="form1.voteSubject"
            disabled
            :options="zhutiObj"
          />
        </el-form-item>
        <el-form-item label="会议议题">
          <v-input v-model="form1.voteTitle" disabled />
        </el-form-item>
        <el-form-item label="记录人">
          <v-input v-model="form1.voteRecorder" disabled />
        </el-form-item>
        <el-form-item label="会议内容">
          <v-input type="textarea" v-model="form1.voteContent" disabled />
        </el-form-item>
        <el-form-item label="同意人数">
          <div>{{ form1.agreeNumber || 0 }}人</div>
        </el-form-item>
        <el-form-item label="反对人数">
          <div>{{ form1.disagreeNumber || 0 }}人</div>
        </el-form-item>
        <el-form-item label="弃权人数">
          <div>{{ form1.abstainNumber || 0 }}人</div>
        </el-form-item>
        <el-form-item label="是否通过">
          <v-select clearable v-model="form2.status" :options="statusObj2" />
        </el-form-item>
        <div v-if="form2.status == '9'">
          <el-form-item label="公示结束时间">
            <v-date-picker
              v-model="form2.gongshiEndTime"
              type="datetime"
              formatValue="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
          <el-form-item label="公示内容">
            <v-input type="textarea" v-model="form2.gongshiContent" />
          </el-form-item>
          <el-form-item label="上传附件">
            <v-uploadFile
              v-model="form2.gongshiAnnexFiles"
              ref="uploadFile"
              :accept="'.xlsx,.doc,.docx,.pdf'"
              :showMessage="true"
              :fileSize="20000"
              text="点击上传"
              :tip="'建议上传pdf文件'"
            />
          </el-form-item>
        </div>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  setStatusOps,
  isTopMap,
  setIsTopOps,
  statusMap,
  kindsListMap,
  kindsList,
  secKindsList,
} from "./map.js";
import {
  getVoteHallEventListUrl,
  examineVoteHallEventUrl,
  deleteMyProposeEventUrl,
  getVoteHallEventDetailUrl,
} from "./api.js";
export default {
  name: "proposalPublicity",
  data() {
    return {
      setIsTopOps: setIsTopOps(),
      zhutiObj: [
        {
          value: "1",
          label: "党员",
        },
        {
          value: "2",
          label: "干部",
        },
        {
          value: "3",
          label: "村民代表",
        },
        {
          value: "4",
          label: "全体居民",
        },
      ],
      statusObj: [
        {
          value: "9",
          label: "通过",
        },
        {
          value: "7",
          label: "待审核",
        },
        {
          value: "10",
          label: "未通过",
        },
        {
          value: "7,9,10",
          label: "全部",
        },
      ],
      statusObj2: [
        {
          value: "9",
          label: "公示通过",
        },
        {
          value: "10",
          label: "公示不通过",
        },
      ],
      showDialog1: false,
      searchParam: {
        proposeTimeS: "",
        proposeTimeE: "",
        statusList: "7,9,10",
      },
      form1: {},
      form2: {
        id: null,
        status: null,
        gongshiContent: null,
        gongshiEndTime: null,
        gongshiAnnexFiles: "",
      },
      tableUrl: getVoteHallEventListUrl,
      headers: [
        {
          prop: "proposeUserName",
          label: "提议发起人",
        },
        {
          prop: "proposeTitle",
          label: "提议主题",
        },
        {
          prop: "proposeContent",
          label: "提议内容",
        },
        {
          prop: "proposeTime",
          label: "发起时间",
        },
      ],
    };
  },
  computed: {
    jueyiTime: {
      get() {
        return !this.form1.jueyiStartTime && !this.form1.jueyiEndTime
          ? []
          : [this.form1.jueyiStartTime, this.form1.jueyiEndTime];
      },
      set(val) {
        [this.form1.jueyiStartTime, this.form1.jueyiEndTime] = val || ["", ""];
      },
    },
    proposeTime: {
      get() {
        return !this.searchParam.proposeTimeS && !this.searchParam.proposeTimeE
          ? []
          : [this.searchParam.proposeTimeS, this.searchParam.proposeTimeE];
      },
      set(val) {
        [this.searchParam.proposeTimeS, this.searchParam.proposeTimeE] =
          val || ["", ""];
      },
    },
  },
  methods: {
    examine(item) {
      this.showDialog1 = true;
      this.form2.id = item.id;
      let params = {
        id: item.id,
      };
      this.$axios
        .get(`${getVoteHallEventDetailUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.form1 = res.data;
            if (typeof this.form1.voteSubject == "string") {
              this.form1.voteSubject = this.form1.voteSubject.split(",");
            }
          }
        });
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post(`${deleteMyProposeEventUrl}?id=${item.id}`)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            }
          });
      });
    },
    submit1() {
      if (this.form2.status == "9") {
        if (!this.form2.gongshiEndTime) {
          this.$message("请选择公示结束时间");
          return;
        }
        if (!this.form2.gongshiContent) {
          this.$message("请填写公示内容");
          return;
        }
        let params = this.form2;
        this.$axios.post(`${examineVoteHallEventUrl}`, params).then((res) => {
          if (res.code == 200) {
            this.showDialog1 = false;
            this.$message.success("操作成功！");
            this.$refs.list.search();
          }
        });
      } else if (this.form2.status == "10") {
        let params = {
          id: this.form2.id,
          status: this.form2.status,
        };
        this.$axios.post(`${examineVoteHallEventUrl}`, params).then((res) => {
          if (res.code == 200) {
            this.showDialog1 = false;
            this.$message.success("操作成功！");
            this.$refs.list.search();
          }
        });
      } else {
        if (!this.form2.status) {
          this.$message("请选择是否通过");
          return;
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
